import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import NavBar from "../components/NavBar"
import Metatags from "../components/metatags"
import Footer from "../components/Footer.en"

const IndexEN = ({ location, data }) => {
  return (
    <>
      <Metatags
        title="Multilógica | About us"
        description="Mattress machinery supplier"
        url="https://newsite.multilogica.ind.br/en/about-us"
        image="https://newsite.multilogica.ind.br/Multilogica-icon.png"
        keywords="mattress, sewing machine, mammut, beckmann sew"
        language="en"
      />
      <NavBar location={location["pathname"]} />
      <div className="w-full">
        <GatsbyImage
          image={getImage(data.bannerAbout)}
          backgroundColor={false}
          alt="mattress machines"
        />
      </div>
      <div className="bg-mi-blue">
        <div className="container mx-auto">
          <div className="h-20" />
          <div className="max-w-xl mx-auto text-center">
            <div className="text-white text-4xl font-semibold m-4">
              About us
            </div>
          </div>
          <div className="h-20" />
        </div>
      </div>
      <div className="h-0 md:h-8" />
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="col-span-1 place-self-center mr-12">
            <div className="text-mi-blue text-xl m-4">
              Founded in 1990 with emphasis in electronis and industrial
              automation, the Multiógica has been providing development, repair
              and automation services for industrial machines in several areas,
              ranging from the glass industry, pharmaceutical and mainly the
              mattress industry, encompassing machinery and spare parts trade.
            </div>
          </div>
          <div className="col-span-1">
            <GatsbyImage
              image={getImage(data.about1)}
              backgroundColor={false}
              alt="machine maintenance"
            />
          </div>
        </div>
        <div className="h-0 md:h-8" />
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="col-span-1 place-self-center mr-12">
            <div className="text-mi-blue text-xl m-4">
              Guided by ethical and moral principles we aim for excellence in
              service, providing the best known machines on the market and the
              best services, linking manufacturers with the end customer, and
              tanging the cutting edge of technology in high performance
              machines.
            </div>
          </div>
          <div className="col-span-1">
            <GatsbyImage
              image={getImage(data.about2)}
              backgroundColor={false}
              alt="machine maintenance"
            />
          </div>
        </div>
        <div className="h-0 md:h-8" />
      </div>
      <div className="bg-mi-gray">
        <div className="h-20" />
        <div className="mx-auto text-center">
          <div className="text-mi-blue text-2xl font-semibold">
            A story based on experience and mutual trust with the customer.
          </div>
        </div>
        <div className="h-20" />
      </div>
      <div className="h-20" />
      <div className="container mx-auto">
        <div className="h-0 md:h-8" />
        <div className="max-w-4xl  mx-auto">
          <div className="text-mi-blue text-xl  font-bold m-4">
            Commitment and Trust
          </div>
          <div className="text-mi-blue text-xl  font-semibold m-4">
            We are committed and responsable for our performance in all our
            decisions and actions.
          </div>
          <div className="text-mi-blue text-xl  font-bold m-4">
            Experience and Competence
          </div>
          <div className="text-mi-blue text-xl  font-semibold m-4">
            Our mission is deeply integrated with excellence: collaboration,
            analysis and development of customized solutions.
          </div>
          <div className="text-mi-blue text-xl  font-bold m-4">
            Integrity and Ethics
          </div>
          <div className="text-mi-blue text-xl  font-semibold m-4">
            We believe in honesty, ethics and integrity, which is why we make a
            decisive contribution to our surroundings with the aim of positively
            impacting society.
          </div>
        </div>
        <div className="h-20" />
      </div>
      <Footer lang="en" />
    </>
  )
}

export default IndexEN

export const aboutEN = graphql`
  query {
    bannerAbout: file(relativePath: { eq: "about-us/Banner_quem_somos.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1920)
      }
    }
    about1: file(relativePath: { eq: "about-us/Galeria_SobreNos_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1920)
      }
    }
    about2: file(relativePath: { eq: "about-us/Galeria_SobreNos_2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1920)
      }
    }
  }
`
